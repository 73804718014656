import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { ThemeProvider } from "@mui/material/styles"

import { theme } from "./theme"
import Event from "./Event"
import PartnerEvent from "./PartnerEvent"
import Layout from "Layout"

import dayjs from "dayjs"
import "dayjs/locale/fr"
dayjs.locale("fr")

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<Layout>
				<Router>
					<Routes>
						<Route path="/" element={<Event />} />
						<Route path="/partner" element={<PartnerEvent />} />
					</Routes>
				</Router>
			</Layout>
		</ThemeProvider>
	)
}

export default App
