import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import {
	Alert,
	Avatar,
	Box,
	Button,
	CircularProgress,
	Typography
} from "@mui/material"
import dayjs from "dayjs"
import PlaceIcon from "@mui/icons-material/Place"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber"
import { getUrl } from "utils/api"

const PartnerEvent = () => {

	const [searchParams] = useSearchParams()
	const token = searchParams.get("token")

	const [event, setEvent] = useState<any>(null)
	const [error, setError] = useState<string | JSX.Element>("")

	useEffect(
		() => {
			if (!token) {
				setEvent(undefined)
				setError("Aucun événement trouvé")
				return
			}

			const getTicket = async () => {
				const response = await fetch(
					getUrl("/partner-events/use-ticket"),
					{
						headers: new Headers({
							"Content-Type": "application/json"
						}),
						body: JSON.stringify({ token }),
						method: "POST"
					}
				)

				const data = await response.json()

				if (data.statusCode >= 400) {
					setEvent(undefined)
					setError(
						<>
							<p style={{ marginTop: 0 }}>{data.message}</p>
							<Button
								variant="contained"
								size="small"
								color="error"
								onClick={getTicket}
							>
								Réessayer
							</Button>
						</>
					)
				} else {
					setEvent(data.partnerEvent)
				}
			}

			getTicket()
		},
		[token]
	)

	if (event === null) {
		return <CircularProgress />
	}

	if (error !== "") {
		return (
			<Alert severity="error">
				{error}
			</Alert>
		)
	}

	return (
		<>
			<Box sx={{ marginBottom: 3, display: "flex", justifyContent: "center" }}>
				<Avatar
					variant="rounded"
					sx={(theme) => ({
						backgroundColor: event.tickets.used <= event.tickets.sent
							? theme.palette.success.main
							: theme.palette.warning.main,
						flexDirection: "column",
						width: "9rem",
						height: "9rem"
					})}
				>
					<Typography sx={{ lineHeight: 1, fontSize: "4.5rem", fontWeight: "bold", marginBottom: 1 }}>
						{event.tickets.used}
					</Typography>
					<Typography sx={{ lineHeight: 1, fontSize: "1.25rem" }}>
						{event.tickets.used > 1 ? "places" : "place"} / {event.tickets.sent}
					</Typography>
				</Avatar>
			</Box>
			<Box sx={{ marginBottom: 3, textAlign: "center" }}>
				<Typography sx={{ lineHeight: 1.25, fontSize: "2rem" }}>
					{event.name}
				</Typography>
			</Box>
			<Box sx={{ marginBottom: 3, textAlign: "center" }}>
				<Typography sx={{ color: "GrayText", marginBottom: ".5rem" }}>
					<CalendarTodayIcon
						fontSize="small"
						sx={{ display: "inline-flex", verticalAlign: "middle", marginRight: ".75rem" }}
					/>
					{dayjs(event.startTime).format("DD MMMM YYYY[, à ]HH[ h ]mm")}
				</Typography>
				<Typography sx={{ color: "GrayText", marginBottom: ".5rem" }}>
					<PlaceIcon
						fontSize="small"
						sx={{ display: "inline-flex", verticalAlign: "middle", marginRight: ".75rem" }}
					/>
					{event.place.split("\n")[0]}
				</Typography>
				<Typography sx={{ color: "GrayText", marginBottom: ".5rem" }}>
					<ConfirmationNumberIcon
						fontSize="small"
						sx={{ display: "inline-flex", verticalAlign: "middle", marginRight: ".75rem" }}
					/>
					Total : {event.tickets.total} / Envoyés : {event.tickets.sent} / Utilisés : {event.tickets.used}
				</Typography>
			</Box>
		</>
	)
}

export default PartnerEvent
