import React from "react"
import { Avatar, Box, Container } from "@mui/material"

import { isPlatform } from "utils/api"

const Layout = ({ children }: { children: React.ReactNode }) => (
	<Container
		maxWidth="sm"
		sx={{ marginY: 9, display: "flex", flexDirection: "column", alignItems: "center" }}
	>
		{children}
		<Box
			sx={{
				display: "flex",
				width: "100%",
				justifyContent: "center",
				marginTop: 6,
				padding: 2,
				borderTop: "1px solid",
				borderColor: "divider",
				boxSizing: "border-box"
			}}
		>
			{isPlatform("arena")
				? (
					<Avatar
						alt="Arena Loire contacts"
						src="/logo-arena-loire-small.jpg"
						variant="square"
						sx={{ width: "10.5rem", height: "4rem", marginTop: 1 }}
					/>
				)
				: (
					<Avatar
						alt="Trélazé contacts"
						src="/logo192.png"
						variant="square"
						sx={{ width: "5rem", height: "5rem" }}
					/>
				)
			}
		</Box>
	</Container>
)

export default Layout
