import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import {
	Alert,
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Collapse,
	Divider,
	Grid,
	Stack,
	TextField,
	Typography
} from "@mui/material"
import dayjs from "dayjs"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import PlaceIcon from "@mui/icons-material/Place"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import { getUrl } from "utils/api"

const guestCountMin = 1
const guestCountMax = 10

const Event = () => {

	const [isReadOnly, setIsReadOnly] = useState(false)
	const [isAccepted, setIsAccepted] = useState<boolean | null>(null)
	const [guestCount, setGuestCount] = useState(1)

	const [searchParams] = useSearchParams()
	const token = searchParams.get("token")

	const [event, setEvent] = useState<any>(null)
	const [error, setError] = useState("")
	const [showDescription, setShowDescription] = useState(false)

	useEffect(
		() => {
			if (!token) {
				setEvent(undefined)
				setError("Aucun événement trouvé")
				return
			}

			const getEvent = async () => {
				const response = await fetch(
					getUrl("/events/summary"),
					{
						headers: new Headers({
							"Content-Type": "application/json"
						}),
						body: JSON.stringify({ token }),
						method: "POST"
					}
				)

				const data = await response.json()

				if (data.statusCode >= 400) {
					setEvent(undefined)
					setError(data.message)
				} else {
					setEvent(data.event)
					setIsReadOnly(data.eventContact.response !== null)
					setIsAccepted(data.eventContact.response)
					setGuestCount(data.eventContact.guestCount || 1)
				}
			}

			getEvent()
		},
		[token]
	)

	const manageResponse = async (state: boolean) => {
		let url = getUrl("/events")

		if (state === true) {
			url += `/accept`
		} else {
			url += `/refuse`
		}

		const response = await fetch(
			url,
			{
				headers: new Headers({
					"Content-Type": "application/json"
				}),
				body: JSON.stringify({ token, guestCount }),
				method: "POST"
			}
		)

		const data = await response.json()

		if (data.statusCode >= 400) {
			setError("Un erreur est survenue, merci de réessayer ultérieurement.")
		} else {
			setIsReadOnly(true)
		}
	}

	if (event === null) {
		return <CircularProgress />
	}

	if (error !== "") {
		return (
			<Alert severity="error">
				{error}
			</Alert>
		)
	}

	return (
		<>
			<Box sx={{ marginBottom: 3, display: "flex", justifyContent: "center" }}>
				<Avatar
					variant="rounded"
					sx={(theme) => ({
						backgroundColor: theme.palette.primary.main,
						flexDirection: "column",
						width: "9rem",
						height: "9rem"
					})}
				>
					<Typography sx={{ lineHeight: 1, fontSize: "5.25rem" }}>
						{dayjs(event.startTime).format("DD")}
					</Typography>
					<Typography sx={{ lineHeight: 1, fontSize: "2.25rem", fontWeight: "bold" }}>
						{dayjs(event.startTime).format("MMM")}
					</Typography>
				</Avatar>
			</Box>
			<Box sx={{ marginBottom: 3, textAlign: "center" }}>
				<Typography sx={{ lineHeight: 1.25, fontSize: "2rem" }}>
					{event.name}
				</Typography>
			</Box>
			<Box sx={{ marginBottom: 3, textAlign: "center" }}>
				<Typography sx={{ color: "GrayText", marginBottom: ".5rem" }}>
					<CalendarTodayIcon
						fontSize="small"
						sx={{ display: "inline-flex", verticalAlign: "middle", marginRight: ".75rem" }}
					/>
					{dayjs(event.startTime).format("DD MMMM YYYY[, à ]HH[ h ]mm")}
				</Typography>
				<Typography sx={{ color: "GrayText", marginBottom: ".5rem" }}>
					<PlaceIcon
						fontSize="small"
						sx={{ display: "inline-flex", verticalAlign: "middle", marginRight: ".75rem" }}
					/>
					{event.place.split("\n")[0]}
				</Typography>
				{!!event.description && (
					<Button
						endIcon={<ExpandMoreIcon/>}
						size="small"
						onClick={() => setShowDescription(value => !value)}
						sx={
							showDescription
								? {
									"& .MuiButton-endIcon": {
										transform: "rotate(180deg)"
									}
								}
								: undefined
						}
					>
						{showDescription ? "Moins d'infos" :  "Plus d'infos"}
					</Button>
				)}
				<Collapse in={showDescription}>
					<Card variant="outlined" sx={{ marginTop: "1rem" }}>
						<CardContent>
							<Typography
								variant="body2"
								color="text.secondary"
								align="left"
								sx={{ marginBottom: "1rem", whiteSpace: "pre-line"}}
							>
								{event.description}
							</Typography>
							<Divider sx={{ marginBottom: "1rem"}} />
							<Grid container spacing={2}>
								<Grid item xs={2} sm={1}>
									<Typography
										variant="body2"
										color="text.secondary"
									>
										<CalendarTodayIcon fontSize="small" />
									</Typography>
								</Grid>
								<Grid item xs={10} sm={5}>
									<Typography
										variant="body2"
										color="text.secondary"
										align="left"
									>
										{`du ${dayjs(event.startTime).format("DD MMMM YYYY[, à ]HH[ h ]mm")}`}
										<br/>
										{`au ${dayjs(event.endTime).format("DD MMMM YYYY[, à ]HH[ h ]mm")}`}
									</Typography>
								</Grid>
								<Grid item xs={2} sm={1}>
									<Typography
										variant="body2"
										color="text.secondary"
									>
										<PlaceIcon fontSize="small" />
									</Typography>
								</Grid>
								<Grid item xs={10} sm={5}>
									<Typography
										variant="body2"
										color="text.secondary"
										align="left"
										sx={{ whiteSpace: "pre-line" }}
									>
										{event.place}
									</Typography>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Collapse>
			</Box>
			{!isReadOnly && (
				<>
					<Stack spacing={2} direction="row" sx={{ marginBottom: 3 }}>
						<Button
							variant={isAccepted === true ? "contained" : "outlined"}
							disableElevation
							size="small"
							color="success"
							onClick={() => setIsAccepted(true)}
							startIcon={<CheckIcon />}
						>
							Accepter
						</Button>
						<Button
							variant={isAccepted === false ? "contained" : "outlined"}
							disableElevation
							size="small"
							color="error"
							onClick={() => setIsAccepted(false)}
							startIcon={<CloseIcon />}
						>
							Refuser
						</Button>
					</Stack>
					{isAccepted === true &&
						<Box sx={{ marginBottom: 3 }}>
							<TextField
								type="number"
								inputProps={{ min: guestCountMin, max: guestCountMax }}
								required
								label="Nombre d'invité(s)"
								defaultValue={guestCountMin}
								onChange={(event) => setGuestCount(Number(event.target.value))}
							/>
						</Box>
					}
					{(
						isAccepted === false
						|| (isAccepted === true && guestCount >= guestCountMin && guestCount <= guestCountMax)
					) &&
						<Button
							variant="contained"
							disableElevation
							size="large"
							onClick={() => manageResponse(isAccepted)}
						>
							Valider
						</Button>
					}
				</>
			)}
			{isReadOnly && (
				<>
					<Box>
						{isAccepted
							? (
								<Stack spacing={2} direction="row" alignItems="center">
									<Button
										variant="contained"
										disableElevation
										size="small"
										color="success"
										startIcon={<CheckIcon />}
										sx={{ pointerEvents: "none" }}
									>
										Accepté
									</Button>
									<Typography sx={{ color: "GrayText" }}>
										{guestCount > 1 ? `${guestCount} invités`: `${guestCount} invité`}
									</Typography>
								</Stack>
							)
							: (
								<Button
									variant="contained"
									disableElevation
									size="small"
									color="error"
									startIcon={<CloseIcon />}
									sx={{ pointerEvents: "none" }}
								>
									Refusé
								</Button>
							)
						}
					</Box>
				</>
			)}
		</>
	)
}

export default Event
